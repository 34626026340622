import { useMemo, ReactNode } from "react";
// material
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import {
  createTheme,
  ThemeOptions,
  ThemeProvider,
  StyledEngineProvider,
  Theme,
  alpha,
} from "@mui/material/styles";
// hooks
// import useSettings from "../hooks/useSettings";
//
import palette from "../theme/palette";
import typography from "../theme/typography";
import breakpoints from "../theme/breakpoints";
import componentsOverride from "../theme/overrides";
import shadows, { customShadows } from "../theme/shadows";

// ----------------------------------------------------------------------

type ThemeConfigProps = {
  children: ReactNode;
};

const isLight = true;
const contrast = true;
const themeContrast = "bold" as string;

const useContrastTheme = (defaultTheme: Theme) => {
  const isLight = defaultTheme.palette.mode === "light";

  const shadowColor = isLight
    ? defaultTheme.palette.grey[500]
    : defaultTheme.palette.common.black;

  const styles = {
    bgDefault: defaultTheme.palette.background.default,
    bgBold: isLight
      ? defaultTheme.palette.grey[100]
      : defaultTheme.palette.grey[900],
    cardDefault: defaultTheme.components?.MuiCard?.styleOverrides?.root,
    cardBold: {
      zIndex: 0,
      position: "relative",
      borderRadius: Number(defaultTheme.shape.borderRadius) * 2,
      boxShadow: `0 0 1px 0 ${alpha(shadowColor, 0.48)}, 0 2px 4px -1px ${alpha(
        shadowColor,
        0.24
      )}`,
    },
  } as const;

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      ...defaultTheme,
      palette: {
        ...defaultTheme.palette,
        background: {
          ...defaultTheme.palette.background,
          default: themeContrast === "bold" ? styles.bgBold : styles.bgDefault,
        },
      },
      components: {
        MuiCard: {
          styleOverrides: {
            root:
              themeContrast === "bold" ? styles.cardBold : styles.cardDefault,
          },
        },
      },
    }),

    [
      defaultTheme,
      themeContrast,
      styles.bgBold,
      styles.bgDefault,
      styles.cardBold,
      styles.cardDefault,
    ]
  );

  if (!contrast) {
    return defaultTheme;
  }

  const theme = createTheme(themeOptions);
  return theme;
};

export const getIsArt = (theme: Theme) => {
  return (theme as any).art === true;
};

export function ThemeConfig({ children }: ThemeConfigProps) {
  // const { themeMode, themeDirection } = useSettings();
  const themeDirection = "ltr";

  const themeOptions: ThemeOptions = useMemo(
    () =>
      ({
        palette: isLight ? palette.light : palette.dark,
        typography,
        breakpoints,
        shape: { borderRadius: 8 },
        direction: themeDirection,
        shadows: isLight ? shadows.light : shadows.dark,
        customShadows: isLight ? customShadows.light : customShadows.dark,
        art: true,
      } as any),
    [isLight, themeDirection]
  );

  let theme = createTheme(themeOptions);

  theme = useContrastTheme(theme);

  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            body: {
              backgroundColor: `${theme.palette.background.default} !important`,
            },
          }}
        />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
