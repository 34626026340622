import create from "zustand";
import { immer } from "zustand/middleware/immer";
import { useBackendStore } from "../../../../../stores/backendStore";
import { ProductType } from "../../../../../stores/productStore";

type LineType = ProductType & {
  quantity: number;
};

interface OrderStoreState {
  lines: LineType[];
  addLine(product: ProductType, quantity: number): void;
  clearLines(): void;
  createOrder(name: string): void;
}

export const useOrderStore = create(
  immer<OrderStoreState>((set, get) => ({
    lines: [],
    addLine(product, quantity) {
      set((state) => {
        const line = state.lines.find((item) => {
          return item.productNumber === product.productNumber;
        });
        if (quantity) {
          if (line) {
            line.quantity = quantity;
          } else {
            state.lines.push({ ...product, quantity });
          }
        } else {
          if (line) {
            const index = state.lines.indexOf(line);
            state.lines.splice(index, 1);
          }
        }
      });
    },
    clearLines() {
      set((state) => {
        state.lines = [];
      });
    },
    async createOrder(name) {
      try {
        const res = await useBackendStore
          .getState()
          .backend.post("/economic/quotes/drafts", {
            name,
            lines: get().lines,
          });
        get().clearLines();
        alert("Tilbud blev oprettet med tilbudsnr: " + res.data.quoteNumber);
      } catch (error) {
        alert("Fejl: Kunne ikke oprette tilbud i econmic.");
      }
    },
  }))
);

export const linesSelector = (state: OrderStoreState) => {
  return state.lines;
};
