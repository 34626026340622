import axios, { AxiosInstance } from "axios";

import create from "zustand";
import { combine } from "zustand/middleware";

// Constants
export const initBackend = (config: { baseURL: string; isProd: boolean }) => {
  useBackendStore.getState().setBackend(
    axios.create({
      baseURL: config.baseURL,
      timeout: 20000,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }),
    config.isProd
  );
};

//
export const useBackendStore = create(
  combine(
    {
      backend: axios as AxiosInstance,
      isProd: false,
    },
    (set, get) => ({
      setBackend: (backend: AxiosInstance, isProd: boolean) => {
        set({ backend, isProd });
      },
      setAuthorization: (accessToken: string) => {
        get().backend.defaults.headers.common.Authorization = accessToken;
      },
      deleteAuthorization: () => {
        delete get().backend.defaults.headers.common.Authorization;
      },
    })
  )
);
