import create from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import { useBackendStore } from "./backendStore";

const SESSION_STORAGE_KEY = "AUTH";

interface AuthState {
  token: string | null;
  loading: boolean;
  error: boolean | null;
  login(body: {
    username: string;
    password: string;
    remember: boolean;
  }): Promise<LoginPostPayload | undefined>;
  authenticate(): Promise<LoginPostPayload | undefined>;
  logout(): void;
}

interface LoginPostPayload {
  accessToken: string;
}

const getIsPersistant = (): boolean => {
  return !!localStorage.getItem(SESSION_STORAGE_KEY);
};

const getAuthentication = (): LoginPostPayload | null => {
  try {
    const authentication =
      sessionStorage.getItem(SESSION_STORAGE_KEY) ||
      localStorage.getItem(SESSION_STORAGE_KEY);

    return !!authentication ? JSON.parse(authentication) : null;
  } catch (error) {
    sessionStorage.removeItem(SESSION_STORAGE_KEY);
    localStorage.removeItem(SESSION_STORAGE_KEY);
    return null;
  }
};

const setAuthentication = (
  authentication: LoginPostPayload,
  persistant: boolean = false
) => {
  sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(authentication));
  if (persistant) {
    localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(authentication));
  }
};

const removeAuthentication = () => {
  sessionStorage.removeItem(SESSION_STORAGE_KEY);
  localStorage.removeItem(SESSION_STORAGE_KEY);
};

export const useAuthStore = create<AuthState>()(
  subscribeWithSelector((set) => ({
    token: null,
    error: null,
    loading: false,

    async login({ username, password, remember }) {
      try {
        set({ loading: true });
        const response = await useBackendStore
          .getState()
          .backend.post<LoginPostPayload[]>("/auth/login", {
            username,
            password,
          });
        setAuthentication(response.data[0], remember);
        set({
          token: response.data[0].accessToken,
          error: false,
          loading: false,
        });

        return response.data[0];
      } catch (error) {
        removeAuthentication();
        set({ token: null, error: true, loading: false });
        throw error;
      }
    },
    async authenticate() {
      try {
        set({ loading: true });

        const authentication = getAuthentication();

        const response = await useBackendStore
          .getState()
          .backend.post<LoginPostPayload[]>("/auth/authenticate", {
            accessToken: authentication?.accessToken,
          });

        setAuthentication(response.data[0], getIsPersistant());
        set({
          token: response.data[0].accessToken,
          error: false,
          loading: false,
        });

        return response.data[0];
      } catch (error) {
        removeAuthentication();
        set({ token: null, error: true, loading: false });
      }
    },
    async logout() {
      removeAuthentication();
      set({ token: null, error: true, loading: false });
    },
  }))
);

useAuthStore.subscribe(
  (state) => state.token,
  (accessToken) => {
    if (accessToken) {
      useBackendStore.getState().setAuthorization(accessToken);
    } else {
      useBackendStore.getState().deleteAuthorization();
    }
  }
);
