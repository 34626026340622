import React from "react";

import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import {
  TopBarTabs,
  TopBarTabsItem,
} from "../../../../components/Tabs/ToppBarTabs";
import { useAuthStore } from "../../../../stores/authStore";

export interface ApplicationBarProps {
  routes: TopBarTabsItem[];
}
export const ApplicationBar = (props: ApplicationBarProps) => {
  const handleLogout = () => {
    useAuthStore.getState().logout();
  };
  return (
    <AppBar>
      <Toolbar>
        <Box flex={1}>
          <Typography variant="h6" component="div">
            FP Entreprise ApS
          </Typography>
        </Box>
        <TopBarTabs tabItems={props.routes} />
        <Box flex={1} textAlign="right">
          <Button color="inherit" onClick={handleLogout}>
            Log ud
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
