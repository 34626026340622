import React from "react";

import { styled } from "@mui/material/styles";

import Tabs, { TabsProps } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import {
  matchRoutes,
  useNavigate,
  useLocation,
  useResolvedPath,
} from "react-router-dom";

// Styles

const StyledTab = styled(Tab)(() => ({ height: 64 }));

// Types
export type TopBarTabsItem = { path: string; label: string };

//
export interface TopBarTabsProps {
  tabItems: TopBarTabsItem[];
  indicatorColor?: TabsProps["indicatorColor"];
}
export const TopBarTabs: React.FunctionComponent<TopBarTabsProps> = (props) => {
  const location = useLocation();
  const parentPathname = useResolvedPath("./");
  let paths = matchRoutes(
    props.tabItems.map((item) => ({
      path: item.path + "/*",
      element: item.path,
      caseSensitive: false,
    })),
    location,
    parentPathname.pathname
  );
  const navigate = useNavigate();
  const handleChange = React.useCallback(
    (_: any, path: string) => {
      navigate(path);
    },
    [navigate]
  );

  const value = paths && paths[0].route.element;

  return (
    <Tabs
      value={value || false}
      onChange={handleChange}
      scrollButtons="auto"
      variant="scrollable"
      indicatorColor={props.indicatorColor || "secondary"}
      textColor="inherit"
    >
      {props.tabItems.map((item) => (
        <StyledTab
          disableTouchRipple
          key={item.path}
          label={item.label}
          value={item.path}
        />
      ))}
    </Tabs>
  );
};
