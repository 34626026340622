import React from "react";

import Box from "@mui/material/Box";

import { Navigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import { useAuthStore } from "../../stores/authStore";

//
export interface AuthGuardProps {
  children?: React.ReactNode | null;
}
export const AuthGuard = (props: AuthGuardProps) => {
  const authentication = useAuthStore();

  const authenticate = authentication.authenticate;

  React.useEffect(() => {
    if (!useAuthStore.getState().loading) {
      verify();
    }
  }, []);

  const verify = async () => {
    try {
      await authenticate();
    } catch (error) {}
  };

  if (authentication.error && !authentication.loading) {
    const backTo = window.location.pathname + window.location.search;
    return <Navigate to="/auth/login" state={{ backTo }} replace />;
  }
  if (!authentication.token || authentication.loading) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return <React.Fragment>{props.children}</React.Fragment>;
};
