import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";

import { SIDEBAR_WIDTH } from "../../../../constants";
import React, { useEffect, useMemo } from "react";
import Fab from "@mui/material/Fab";
import Icon from "@mui/material/Icon";
import Hidden from "@mui/material/Hidden";
import useResponsive from "../../../../../../hooks/useResponsive";
import {
  categoryGetter,
  productsSelector,
  useProductStore,
} from "../../../../../../stores/productStore";

const fabStyle = {
  position: "fixed",
  bottom: 16,
  left: 16,
};

interface CategorySidebarProps {
  onCategorySelect: (category: string) => void;
}
export const CategorySidebar = React.memo<CategorySidebarProps>((props) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };
  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const isDesktop = useResponsive("up", "lg");
  useEffect(() => {
    setIsDrawerOpen(false);
  }, [isDesktop]);

  useEffect(() => {
    useProductStore.getState().getProducts();
  }, []);

  const products = useProductStore(productsSelector);

  const categories = useMemo(() => categoryGetter(products), [products]);

  const content = (
    <React.Fragment>
      <Toolbar sx={{}}>
        <Typography variant="h5" noWrap>
          Kategorier
        </Typography>
      </Toolbar>
      <Divider />
      <List sx={{ flex: 1, flexGrow: 1, overflow: "auto" }}>
        {categories.map((category) => (
          <ListItem key={category.category} disablePadding>
            <ListItemButton
              onClick={() => {
                props.onCategorySelect(category.category);
                handleCloseDrawer();
              }}
            >
              <ListItemText
                primary={category.category}
                secondary={`${category.count} vare`}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <Hidden mdUp>
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={handleCloseDrawer}
          PaperProps={{
            sx: {
              width: SIDEBAR_WIDTH,
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          {content}
        </Drawer>
        <Fab
          sx={fabStyle}
          color="primary"
          aria-label="kategori"
          onClick={handleOpenDrawer}
        >
          <Icon>category</Icon>
        </Fab>
      </Hidden>
      <Hidden mdDown>
        <Grid
          item
          sx={{
            width: SIDEBAR_WIDTH,
          }}
        >
          <Card
            elevation={8}
            sx={{
              position: "fixed",
              width: SIDEBAR_WIDTH,
              left: 24,
              top: "88px",
              bottom: 24,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {content}
          </Card>
        </Grid>
      </Hidden>
    </React.Fragment>
  );
});
