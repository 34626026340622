import React, { useState } from "react";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";

interface CreateQuoteDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit?: (values: { name: string }) => void;
}
export const CreateQuoteDialog = React.memo<CreateQuoteDialogProps>((props) => {
  const [value, setValue] = useState("");

  const handleExited = () => {
    setValue("");
    if (value) {
      props.onSubmit?.({ name: value });
    } else {
      alert("Tilbud kunne ikke oprettes uden et navn.");
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      TransitionProps={{
        onExited: handleExited,
      }}
      fullWidth
      maxWidth={"xs"}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle style={{ paddingBottom: 16 }}>Opret overslag</DialogTitle>
        <DialogContent>
          <DialogContentText paddingBottom={1}>
            Overslag oprettes som tilbud i economics.
          </DialogContentText>
          <TextField
            value={value}
            onChange={(e) => {
              setValue(e.currentTarget.value);
            }}
            margin="dense"
            label="Navn"
            required
            fullWidth
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end" }}>
          <Box flexGrow={1} />
          <Stack direction="row" spacing={2}>
            <Button variant="outlined" type="button" onClick={props.onClose}>
              Annuler
            </Button>
            <Button variant="contained" type="submit">
              Opret
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
});
