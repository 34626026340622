import create from "zustand";
import { useBackendStore } from "./backendStore";

export const EMPTY_CATEGORY = "Andre";

export type ProductType = {
  productNumber: string;
  name: string;
  salesPrice: number;
  image?: string | null;
  category: string;
  unit: string;
};

interface ProductStoreState {
  products: ProductType[] | null;
  getProducts(): Promise<void>;
}

export const useProductStore = create<ProductStoreState>((set) => ({
  products: null,
  async getProducts() {
    try {
      const res = await useBackendStore
        .getState()
        .backend.get<ProductType[]>("/economic/products");
      if (res.data) {
        set({ products: res.data });
      }
    } catch (error) {
      alert("Fejl: Kunne ikke hente produkter. Kontakt system administrator.");
    }
  },
}));

export const productsSelector = (state: ProductStoreState) => state.products;

export const categoryGetter = (products: ProductStoreState["products"]) => {
  return products
    ? Object.entries(
        products.reduce((prev, current, index) => {
          if (current.category) {
            if (prev[current.category]) {
              prev[current.category] = prev[current.category] + 1;
            } else {
              prev[current.category] = 1;
            }
          } else {
            if (prev[EMPTY_CATEGORY]) {
              prev[EMPTY_CATEGORY] = prev[EMPTY_CATEGORY] + 1;
            } else {
              prev[EMPTY_CATEGORY] = 1;
            }
          }
          return prev;
        }, {} as Record<string, number>)
      )
        .map((item) => ({ category: item[0], count: item[1] }))
        .sort((a, b) => {
          const nameA = a.category.toUpperCase(); // ignore upper and lowercase
          const nameB = b.category.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        })
    : [];
};
