import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import numeral from "numeral";
import { ThemeConfig } from "./theme";

import { App } from "./views/App";
import { Auth } from "./views/Auth";

numeral.register("locale", "da-dk", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "k",
    million: "mio",
    billion: "mia",
    trillion: "b",
  },
  ordinal: function (number) {
    return ".";
  },
  currency: {
    symbol: "DKK",
  },
});
numeral.locale('da-dk');

export const Root = () => {
  return (
    <BrowserRouter>
      <ThemeConfig>
        <Routes>
          <Route path="/auth/*" element={<Auth />} />
          <Route path="/*" element={<App />} />
          <Route path="/" element={<Navigate to="/overslag" replace />} />
        </Routes>
      </ThemeConfig>
    </BrowserRouter>
  );
};
