import Box from "@mui/material/Box";
import { Route, Routes } from "react-router-dom";
import { AuthGuard } from "../../components/AuthGuard";
import { ApplicationBar } from "./components/ApplicationBar";
import { CreateOrder } from "./views/CreateOrder";

const routes = [
  { path: "/overslag", label: "Overslag", element: <CreateOrder /> },
];

export const App = () => {
  return (
    <AuthGuard>
      <Box sx={{ paddingTop: "64px", height: "100%" }}>
        <ApplicationBar routes={routes} />
        <Routes>
          {routes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Routes>
      </Box>
    </AuthGuard>
  );
};
