import React, { useEffect, useMemo } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { SIDEBAR_WIDTH } from "../../../../constants";
import Drawer from "@mui/material/Drawer";
import Fab from "@mui/material/Fab";
import Icon from "@mui/material/Icon";
import Hidden from "@mui/material/Hidden";
import useResponsive from "../../../../../../hooks/useResponsive";
import { linesSelector, useOrderStore } from "../../stores/orderStore";
import { IconButton } from "@mui/material";
import { Stack } from "@mui/system";
import numeral from "numeral";
import { CreateQuoteDialog } from "../CreateQuoteDialog";

const fabStyle = {
  position: "fixed",
  bottom: 16,
  right: 16,
};

interface CartSidebarProps {
  onLineSelect: (lineId: string) => void;
}
export const CartSidebar = React.memo<CartSidebarProps>((props) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [isQuoteDialogOpen, setIsQuoteDialogOpen] = React.useState(false);

  const lines = useOrderStore(linesSelector);

  const withoutTax = useMemo(() => {
    return lines.reduce((prev, current, index) => {
      prev =
        prev + parseFloat((current.salesPrice * current.quantity).toFixed(2));

      return prev;
    }, 0);
  }, [lines]);

  const withTax = useMemo(() => {
    return parseFloat((withoutTax * 1.25).toFixed(2));
  }, [withoutTax]);

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };
  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleOpenQuoteDialog = () => {
    setIsQuoteDialogOpen(true);
  };
  const handleCloseQuoteDialog = () => {
    setIsQuoteDialogOpen(false);
  };

  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    setIsDrawerOpen(false);
  }, [isDesktop]);

  const handleCreateOrder = (values: { name: string }) => {
    useOrderStore.getState().createOrder(values.name);
  };

  const handleClearOrder = () => {
    useOrderStore.getState().clearLines();
  };

  const content = (
    <React.Fragment>
      <Toolbar>
        <Typography variant="h5" noWrap>
          Overslag
        </Typography>
        <Box flexGrow={1} />
        <IconButton onClick={handleClearOrder}>
          <Icon>delete</Icon>
        </IconButton>
      </Toolbar>
      <Divider />
      <List sx={{ flex: 1, flexGrow: 1, overflow: "auto" }}>
        {lines.map((line) => (
          <ListItem
            key={line.productNumber}
            button
            onClick={() => props.onLineSelect(line.productNumber)}
          >
            <ListItemText
              primary={line.name}
              secondary={`${line.quantity} x ${line.salesPrice} kr. / ${line.unit}`}
            />
          </ListItem>
        ))}
      </List>
      <Divider />
      <Stack px={3} py={1}>
        <Box display="flex" py={1}>
          <Typography>Ex. moms</Typography>
          <Box flexGrow={1} />
          <Typography fontWeight={"600"}>
            {numeral(withoutTax).format("0,0.00")}
          </Typography>
        </Box>
        <Box display="flex" py={1}>
          <Typography>Inkl. moms</Typography>
          <Box flexGrow={1} />
          <Typography fontWeight={"600"}>
            {numeral(withTax).format("0,0.00")}
          </Typography>
        </Box>
      </Stack>
      <Box sx={{ p: 3, pt: 0 }}>
        <Button variant="contained" fullWidth onClick={handleOpenQuoteDialog}>
          Opret overslag
        </Button>
      </Box>
      <CreateQuoteDialog
        open={isQuoteDialogOpen}
        onClose={handleCloseQuoteDialog}
        onSubmit={handleCreateOrder}
      />
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <Hidden mdUp>
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={handleCloseDrawer}
          PaperProps={{ sx: { width: SIDEBAR_WIDTH } }}
        >
          {content}
        </Drawer>
        <Fab
          sx={fabStyle}
          color="primary"
          aria-label="kategori"
          onClick={handleOpenDrawer}
        >
          <Icon>shopping_cart</Icon>
        </Fab>
      </Hidden>
      <Hidden mdDown>
        <Grid
          item
          sx={{
            width: SIDEBAR_WIDTH,
          }}
        >
          <Card
            elevation={5}
            sx={{
              width: SIDEBAR_WIDTH,
              position: "fixed",
              right: 24,
              top: "88px",
              bottom: 24,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {content}
          </Card>
        </Grid>
      </Hidden>
    </React.Fragment>
  );
});
