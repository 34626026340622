import React, { useState } from "react";

import Grid from "@mui/material/Grid";
import Container from "@mui/system/Container";

import { CategorySidebar } from "./components/CategorySidebar";
import { CartSidebar } from "./components/CartSidebar";
import { ProductList } from "./components/ProductList";
import { AddProductDialog } from "./components/AddProductDialog";

export const CreateOrder = () => {
  const [category, setCategory] = useState<string | null>(null);
  const [isAddProductOpen, setIsAddProductOpen] = useState<boolean>(false);
  const [product, setProduct] = useState<string | null>(null);

  const handleCategorySelect = React.useCallback((category: string) => {
    setCategory(category);
  }, []);

  const handleProductSelect = React.useCallback((productId: string) => {
    setIsAddProductOpen(true);
    setProduct(productId);
  }, []);

  const handleAddProductClose = React.useCallback(() => {
    setIsAddProductOpen(false);
  }, []);

  const handleAddProductExited = React.useCallback(() => {
    setProduct(null);
  }, []);

  return (
    <Container sx={{ py: 3, height: "100%" }} maxWidth={false}>
      <Grid container spacing={0} sx={{ height: "100%" }}>
        <CategorySidebar onCategorySelect={handleCategorySelect} />
        <Grid item flexGrow={1} flex={1}>
          <ProductList
            category={category}
            onProductSelect={handleProductSelect}
          />
        </Grid>
        <CartSidebar onLineSelect={handleProductSelect} />
      </Grid>
      <AddProductDialog
        open={isAddProductOpen}
        product={product}
        onClose={handleAddProductClose}
        onExited={handleAddProductExited}
      />
    </Container>
  );
};
