import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useAuthStore } from "../../stores/authStore";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";

type FormValues = {
  username: string;
  password: string;
  remember: boolean;
};

export const Auth = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleSubmit = async (values: FormValues) => {
    try {
      await useAuthStore.getState().login(values);
      navigate(location.state?.backTo || "/");
    } catch (error) {
      alert("Forkert brugernavn eller adgangskode");
    }
  };
  return (
    <Grid container justifyContent="center" alignItems="center" height={"100%"}>
      <Grid item xs={10} sm={6} md={5} lg={4} xl={3}>
        <Card sx={{ p: 3 }} elevation={8}>
          <Stack spacing={2}>
            <Formik<FormValues>
              initialValues={{ username: "", password: "", remember: false }}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box sx={{ px: 6, py: 2 }}>
                    <img src="/logo.png" alt="FP Entreprise" />
                  </Box>
                  <TextField
                    type="text"
                    name="username"
                    label="Brugernavn"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    name="password"
                    label="Adgangskode"
                    fullWidth
                    margin="normal"
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <FormGroup sx={{ pb: 1 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="remember"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.remember}
                        />
                      }
                      label="Husk mig"
                    />
                  </FormGroup>
                  <Button
                    variant="contained"
                    fullWidth
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Login
                  </Button>
                </form>
              )}
            </Formik>
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
};
