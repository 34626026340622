import React, { useState } from "react";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { Image } from "../../../../../../components/Image";
import { useProductStore } from "../../../../../../stores/productStore";
import { useOrderStore } from "../../stores/orderStore";

const fallbackImage = require("../../../../../../assets/default-fallback-image.png");

interface AddProductDialogProps {
  open: boolean;
  product: string | null;
  onClose: () => void;
  onExited: () => void;
}
export const AddProductDialog = React.memo<AddProductDialogProps>((props) => {
  const [value, setValue] = useState("");

  const product = useProductStore((state) =>
    state.products?.find((item) => item.productNumber === props.product)
  );

  const handleEnter = () => {
    const line = useOrderStore
      .getState()
      .lines.find((item) => item.productNumber === props.product);
    const value = line?.quantity || 1;
    setValue(value.toString());
  };

  const handleExited = () => {
    setValue("");
    props.onExited();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (product) {
      useOrderStore.getState().addLine(product, parseFloat(value));
    }
    props.onClose();
  };

  const handleDelete = () => {
    if (product) {
      useOrderStore.getState().addLine(product, 0);
    }
    props.onClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      TransitionProps={{
        onEnter: handleEnter,
        onExited: handleExited,
      }}
      fullWidth
      maxWidth={"xs"}
    >
      <form onSubmit={handleSubmit}>
        <Box p={3} pb={2}>
          <Stack spacing={3}>
            <Image
              sx={{ borderRadius: 1 }}
              alt=""
              src={product?.image ?? fallbackImage}
              ratio="1/1"
            />
            <Box>
              <Typography variant="h5">
                {product ? product.name : "-"}
              </Typography>

              <Typography variant="subtitle1" color="textSecondary">
                {product ? product.salesPrice : "-"} kr. /{" "}
                {product ? product.unit : "-"}
              </Typography>
            </Box>

            <TextField
              value={value}
              onChange={(e) => {
                setValue(e.currentTarget.value);
              }}
              label="Antal"
              type="number"
              fullWidth
            />
          </Stack>
        </Box>
        <DialogActions sx={{ justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="error"
            type="button"
            onClick={handleDelete}
          >
            Slet
          </Button>
          <Box flexGrow={1} />
          <Stack direction="row" spacing={2}>
            <Button variant="outlined" type="button" onClick={props.onClose}>
              Annuler
            </Button>
            <Button variant="contained" type="submit">
              Tilføj til kurv
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
});
