import numeral from "numeral";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Image } from "../../../../../../components/Image";
import { ProductType } from "../../../../../../stores/productStore";

const fallbackImage = require("../../../../../../assets/default-fallback-image.png");

const StyledButtonBase = styled(ButtonBase)({
  display: "block",
});

interface ProductCardProps {
  product: ProductType;
  onClick: React.MouseEventHandler<any>;
}
export const ProductCard = (props: ProductCardProps) => {
  return (
    <Card component={StyledButtonBase} onClick={props.onClick}>
      <Box sx={{ position: "relative", margin: 2, mb: 0 }}>
        {/* {false && (
        <Label
          variant="filled"
          color={(status === "sale" && "error") || "info"}
          sx={{
            top: 16,
            right: 16,
            zIndex: 9,
            position: "absolute",
            textTransform: "uppercase",
          }}
        >
          {status}
        </Label>
      )} */}
        <Image
          sx={{ borderRadius: 1 }}
          alt=""
          src={props.product.image ?? fallbackImage}
          ratio="1/1"
        />
      </Box>

      <Stack spacing={1} sx={{ p: 3 }}>
        <Typography variant="h6" noWrap align="left">
          {props.product.name}
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle1" color="textSecondary">
            {numeral(props.product.salesPrice).format("0[,][0]")} kr. /{" "}
            {props.product.unit}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};
