import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useMemo } from "react";
import {
  EMPTY_CATEGORY,
  productsSelector,
  useProductStore,
} from "../../../../../../stores/productStore";
import { ProductCard } from "../ProductCard";

interface ProductListProps {
  category: string | null;
  onProductSelect: (productId: string) => void;
}
export const ProductList = React.memo<ProductListProps>((props) => {
  const products = useProductStore(productsSelector);

  const filteredProducts = useMemo(
    () =>
      products?.filter((item) =>
        props.category === EMPTY_CATEGORY
          ? !item.category
          : item.category === props.category
      ),
    [products, props.category]
  );
  return props.category ? (
    <Box sx={{ px: 3, pb: 3, minHeight: "100%" }}>
      <Box
        sx={{
          display: "grid",
          gap: 3,
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
            xl: "repeat(3, 1fr)",
          },
        }}
      >
        {filteredProducts?.map((product) => (
          <ProductCard
            key={product.productNumber}
            product={product}
            onClick={() => props.onProductSelect(product.productNumber)}
          />
        ))}
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h4" color="textSecondary" pb={1}>
        Vælg en kategori
      </Typography>
      <Typography variant="body1" color="textSecondary">
        For at se vare skal du først vælge en kategori
      </Typography>
    </Box>
  );
});
